export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        drugs :'', 
        drug :'', 
    },
    
    getters : {
        drugs: (state )=> state.drugs,
        drug: (state )=> state.drug,
    },


    mutations : { 
        setdrugs :(state,drugs ) => (state.drugs = drugs), 
        setdrug :(state,drug ) => (state.drug = drug), 
    },

    actions : {

        async create_drug( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_drug')
            return new Promise((resolve, reject) => {
            axios.post("/settings/drugs/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrugs', response.data.data)
                toast.success("drug Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_drugs( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_drugs')
            commit('setpageloading', 'get_drugs')
            return new Promise((resolve, reject) => {
            axios.post("/settings/drugs?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrugs', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_drug( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_drug' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_drug( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_drug' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/drugs/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrugs', response.data.data)
                toast.success("Drug Details Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_drug_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_drug_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/settings/drugs/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setdrugs', response.data.data)
                        if (data.status == 1) {
                            toast.success("drug Activated Successfully")
                        } else {
                            toast.success("drug Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_drug( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_drug' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/drugs/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrugs', response.data.data)
                comp_play_delete_file('drug Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    }


    
}