import store from '@/store'
const adminguard = (to, from, next) => {
  const user = store.getters.authuser;
  const userId = user ? user.id : null;
  const isLoggedIn = userId && localStorage.getItem('hospitalmanagementsystem' + userId) !== null;

  console.log('Navigating from:', from.name, 'to:', to.name);
  console.log('User ID:', userId);
  console.log('Is Logged In:', isLoggedIn);
  console.log('User:', user);

  if (isLoggedIn) {
    if (user.userhospital != '') {
      console.log('User has a hospital setup. Proceeding to next route.');
      return next();
    } else {
      console.log('User does not have a hospital setup.');
      if (to.name !== 'setup_hospital') {
        console.log('Redirecting to setup_hospital route.');
        return next({ name: 'setup_hospital' });
      } else {
        console.log('Already on setup_hospital route. Proceeding.');
        return next(); 
      }
    }
  } else {
    console.log('User is not logged in.');
    if (to.name !== 'login') {
      console.log('Redirecting to login route.');
      // return next({ name: 'login' });
    } else {
      console.log('Already on login route. Proceeding.');
      return next(); 
    }
  }
};

let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              children:[

                {
                  path: "/",
                  name: "app_dashboard",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/dashboard.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "/patients",
                  name: "app_patients",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/patient_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },
                {
                  path: "/patients/:id",
                  name: "app_patient_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/patient_details.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },
                {
                  path: "/active-cases",
                  name: "app_active_case_list",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/active_case_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Active Case List'
                  }
                },

                {
                  path: "/drug-shop",
                  name: "app_drug_Shop",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/drug_shop.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "/drug-stock",
                  name: "app_drug_stock",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/drug_stock.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Stock'
                  }
                },

                {
                  path: "/drug-stock/:id",
                  name: "app_drug_stock_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/drug_stock/drug_stock_details.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Stock'
                  }
                },

                {
                  path: "/drug-stock/list",
                  name: "app_drug_stock_list",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/drug_stock/drug_stock_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Drug Stock List'
                  }
                },
                
                {
                  path: "/add-case",
                  name: "app_add_case",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/add_case.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                

                {
                  path: "/add-patient",
                  name: "app_add_patient",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/add_patient.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "/point-of-sale",
                  name: "app_user_posh",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/pos/point_of_sale.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },
                

                {
                  path: "/finances",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/finances/main.vue"),
                  children:[

                    {
                      path: "",
                      name: "app_user_finances",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/finances/dashboard.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Finances Dashboard'
                      }
                    },


                    {
                      path: "income",
                      name: "app_finances_income_report",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/finances/income_report.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Incomes Dashboard'
                      }
                    },

                    {
                      path: "requsitions",
                      name: "app_finances_requsitions",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/finances/requsitions.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Requsitions Dashboard'
                      }
                    },
                  ]
                },

                {
                  path: "/requsitions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/requsitions/main.vue"),
                  children:[

                    {
                      path: "manager",
                      name: "app_user_cash_requsitions",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/requsitions/funds_requsitions.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Cash Requsitions Management'
                      }
                    },

                    {
                      path: "approvals",
                      name: "app_user_requsitions_approvals",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/requsitions/approvals_requsitions.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Inventory Requsitions Management'
                      }
                    },
                  ]
                },

                {
                  path: "/inventory",
                  name: "app_user_inventory",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/inventory/main.vue"),
                  children:[

                    {
                      path: "dashboard",
                      name: "app_user_inventory_dash",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/inventory/inventory_dash.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Manage Inventory'
                      }
                    },

                    {
                      path: "dashboard/issue",
                      name: "app_user_issue_items",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/inventory/issue_items.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Inventory Issue Management'
                      }
                    },
                  ]
                },

                

              ]

              
            },

            {
              path: "/patients/:id",
              name: "app_patients_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dashboard/patient_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Dashboard'
              }
            },

            {
              path: "/cases/:id",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dashboard/case_details.vue"),
  
              children:[

                {
                  path: "",
                  name: "app_case_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_general_report.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "diagnosis",
                  name: "app_case_diagnosis",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_diagnosis.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "lab-tests",
                  name: "app_case_labtests",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_labtests.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "prescribed-drugs",
                  name: "app_case_prescribed_drugs",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_prescribed_drugs.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "issued-drugs",
                  name: "app_case_issued_drugs",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_issued_drugs.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "patient-bill",
                  name: "app_case_patient_bill",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_patient_bill.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "patient-payments",
                  name: "app_case_patient_payments",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_patient_payments.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "patient-triage",
                  name: "app_case_patient_triage",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_patient_triage.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Dashboard'
                  }
                },

                {
                  path: "patient-forms",
                 
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dashboard/case_details/case_patient_forms.vue"),
                  children:[
                    {
                      path: "general-report",
                      name: "app_case_patient_forms",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/case_details/forms/referal_note.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Dashboard'
                      }
                    },

                    {
                      path: "referal-note",
                      name: "app_case_patient_case_referal_note",
                      props:true,
                      beforeEnter: adminguard,
                      component: () => import("@/views/app/dashboard/case_details/forms/referal_note.vue"),
                      meta: {
                        requiresAuth: true,
                      title: 'Dashboard'
                      }
                    },

                  ]
                },


                

              ]
            },


            //settings
            {
              path: "/settings",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/settings/main.vue"),
              children:[
                {
                  path: "",
                  name: "app_Settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/intro.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Hospital Settings'
                  }
                },

                {
                  path: "roles",
                  name: "app_roles",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/roles.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Hospital Settings'
                  }
                },

                {
                  path: "patient-classes",
                  name: "app_patient_classes",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/patient_classes.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Hospital Patient Classes'
                  }
                },

                {
                  path: "permissions",
                  name: "app_permissions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/permissions.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Permission Settings'
                  }
                },

                {
                  path: "role-permissions",
                  name: "app_role_permissions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/role_permissions.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Role Permissions Settings'
                  }
                },

                {
                  path: "hospital-rooms",
                  name: "app_hospital_rooms",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/hospital_rooms.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Room Settings'
                  }
                },

                {
                  path: "hospital-departments",
                  name: "app_hospital_departments",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/departments.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Room Settings'
                  }
                },
                
                {
                  path: "hospital-services",
                  name: "app_hospital_service_list",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/service_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Room Settings'
                  }
                },
                {
                  path: "hospital-lab-tests",
                  name: "app_hospital_lab_tests",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/lab_tests.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Lab Tests Settings'
                  }
                },
                {
                  path: "hospital",
                  name: "app_update_hospital",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/hospital_settings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Update Hospital Settings and Details'
                  }
                },


                //drugs
                {
                  path: "drugs",
                  name: "app_manage_drugs",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/drugs/managedrugs.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drugs'
                  }
                },

                {
                  path: "drugs/categories",
                  name: "app_manage_categories",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/drugs/drugcategories.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Categories'
                  }
                },

                {
                  path: "drugs/classes",
                  name: "app_manage_drug_classes",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/drugs/drugclasses.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Classes'
                  }
                },

                {
                  path: "drugs/groups",
                  name: "app_manage_drug_groups",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/drugs/druggroups.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Groups'
                  }
                },

                {
                  path: "drugs/settings",
                  name: "app_manage_drug_settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/drugs/drugsettings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Drug Settings'
                  }
                },

                //trashbin
                {
                  path: "trash-bin",
                  name: "app_trash_bin",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/trashbin.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Update Hospital Settings and Details'
                  }
                },

              ]
            },


            {
              path: "/human-resource",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/admin/humanresource/main.vue"),
              children:[

                {
                  path: "dashboard",
                  name: "app_humanresource_dash",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/admin/humanresource/dashboard.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Employees and Staff'
                  }
                },
                {
                  path: "employees",
                  name: "app_humanresource_employees",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/admin/humanresource/employees.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Create and Update Employees'
                  }
                },

              ]
             
            },


            {
              path: "/admin/inventory",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/admin/inventory/main.vue"),
              children:[

                {
                  path: "dashboard",
                  name: "app_inventory_dash",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/admin/inventory/inventory_dash.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Employees and Staff'
                  }
                },
                {
                  path: "categories",
                  name: "app_inventory_categories",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/admin/inventory/inventory_categories.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Create and Update Employees'
                  }
                },

                {
                  path: "items",
                  name: "app_inventory_items",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/admin/inventory/inventory_items.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Create and Update Employees'
                  }
                },

              ]
             
            },


   
  

            
        ]
    }
]
export default routes;