
export const namespaced = true 
import { comp_play_error_file, comp_play_success_file } from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {

    actions : {

        async update_hospital_logo( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_hospital_logo')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/logo/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethospital', response.data.data)
                comp_play_success_file('Logo has been Updated Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_hospital_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_hospital_details')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethospital', response.data.data)
                comp_play_success_file('Information has been Updated Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        
    }
}